.card-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  width: 100%;
  height: 100%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}

.carousel--hidden .card {
  transform: scale(0.6) rotate(-1.9deg);
  transition: transform 0.3s;
}

.card::before,
.card::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
}

.card::after {
  top: 0.5rem;
  left: 0.4rem;
  background-size: cover;
  background-repeat: no-repeat;
}

/* .card--event::before { background: #fbe4d4 url('../img/paper-bg.png') center repeat;} */
.card--event::before { background: #fbe4d4;}
.card--fish::before { background: #bceae0;}
.card--communication::before { background: #f9f5b8;}
.card--insect::before { background: #f8f9b5;}
.card--diy::before { background: #f9f2c6;}
.card--hha::before { background: #faf3bf;}
.card--plant::before { background: #e5fac2;}
.card--smartphone::before { background: #bfdef0;}
.card--money::before { background: #faf9b8;}
.card--negative::before { background: #ded9f7;}
.card--landmaking::before { background: #d7efd0;}
.card--mydesign::before { background: #fde4e0;}

.card--event::after { background-image: url('../img/bg-event-large.png'); }
.card--fish::after { background-image: url('../img/bg-fish-large.png'); }
.card--communication::after { background-image: url('../img/bg-communication-large.png'); }
.card--insect::after { background-image: url('../img/bg-insect-large.png'); }
.card--diy::after { background-image: url('../img/bg-diy-large.png'); }
.card--hha::after { background-image: url('../img/bg-hha-large.png'); }
.card--plant::after { background-image: url('../img/bg-plant-large.png'); }
.card--smartphone::after { background-image: url('../img/bg-smartphone-large.png'); }
.card--money::after { background-image: url('../img/bg-money-large.png'); }
.card--negative::after { background-image: url('../img/bg-negative-large.png'); }
.card--landmaking::after { background-image: url('../img/bg-landmaking-large.png'); }
.card--mydesign::after { background-image: url('../img/bg-mydesign-large.png'); }

.card__title {
  margin-bottom: 1rem;
  font-size: 3.5rem;
  display: flex;
  align-items: flex-end;
  color: #5f5228;
  font-weight: 700;
}

.card__description {
  position: relative;
  color: #715a3b;
  font-size: 3.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4rem;
  margin-bottom: 14rem;
  max-width: 80rem;
}

.card__description::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.4;
}

.card__description__nook {
  width: 8.1rem;
  height: 7.6rem;
  position: absolute;
  bottom: 2rem;
  right: -1rem;
  background: url('../img/nook-face.svg') center no-repeat;
  background-size: 100%;
  transform: rotate(10deg);
  display: none;
}

.card-badges {
  display: flex;
  align-items: flex-start;
  position: relative;
  transform: scale(0.8);
}

.card-badges--1 .card-badges__track { height: 14.7rem; }
.card-badges--2 .card-badges__track { height: 13.7rem; }
.card-badges--3 .card-badges__track { height: 13.7rem; }
.card-badges--4 .card-badges__track { height: 13.7rem; }
.card-badges--5 .card-badges__track { height: 13.7rem; }
.card-badges--6 .card-badges__track { height: 12.7rem; }

.card-badges__track {
  height: 100%;
}

@media (min-width: 768px) {
  .card {
    width: 98.5rem;
    height: 59.6rem;
    transform: rotate(-1.9deg);
    box-shadow: 0 0.6rem 0.8rem rgba(0,0,0, 0.1);
    border: 18px solid #fefff1;
    padding-top: 0;
    justify-content: flex-start;
  }

  .card::after {
    background-size: contain;
  }

  .card__title {
    height: 7rem;
    margin-bottom: 3.5rem;
    font-size: 3.2rem;
  }

  .card__description {
    height: 24.7rem;
    width: 78.2rem;
    max-width: none;
    padding: 0 7.5rem;
    margin-bottom: 0;
  }

  .card__description::before {
    background: url('../img/card-description-bg.svg') center no-repeat;
    background-size: 100%;
  }

  .card__description__nook {
    display: block;
  }

  .card-badges {
    align-items: center;
    flex-grow: 1;
    transform: scale(1);
  }
}

/* --------------------- */
/* Text highlight colors */
/* --------------------- */

.color-orange { color: #e27618; }
.color-blue { color: #009ea7; }