/* ------------------- */
/* Stamp reward bubble */
/* ------------------- */

.stamp-reward {
  position: absolute;
  bottom: calc(100% + 0.6rem);
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4rem;
  padding: 0.6rem 1.6rem 0.4rem;
  font-size: 3.6rem;
  color: rgba(245,248,228, 0.7);
  display: flex;
  white-space: nowrap;
}

.achievements .stamp-reward {
  display: none;
}

.stamp-reward--event { background-color: #E89469; }
.stamp-reward--fish { background-color: #33B3B4; }
.stamp-reward--insect { background-color: #99BD2B; }
.stamp-reward--communication { background-color: #f1a400; }
.stamp-reward--diy { background-color: #C3963E; }
.stamp-reward--hha { background-color: #C5B058; }
.stamp-reward--plant { background-color: #5CBB5F; }
.stamp-reward--smartphone { background-color: #8A7BD0; }
.stamp-reward--money { background-color: #B4AA28; }
.stamp-reward--negative { background-color: #7f73ba; }
.stamp-reward--landmaking { background-color: #4fae6a; }
.stamp-reward--mydesign { background-color: #eb8396; }

.stamp-reward::after {
  content: '';
  width: 0;
  height: 2rem;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 0.4rem dashed #399e5f;
}

.stamp-reward--event::after { border-color: #E89469; }
.stamp-reward--fish::after { border-color: #33B3B4; }
.stamp-reward--insect::after { border-color: #99BB2A; }
.stamp-reward--communication::after { border-color: #f1a400; }
.stamp-reward--diy::after { border-color: #C3963E; }
.stamp-reward--hha::after { border-color: #C5B058; }
.stamp-reward--plant::after { border-color: #5CBB5F; }
.stamp-reward--smartphone::after { border-color: #8A7BD0; }
.stamp-reward--money::after { border-color: #B4AA28; }
.stamp-reward--negative::after { border-color: #7f73ba; }
.stamp-reward--landmaking::after { border-color: #4fae6a; }
.stamp-reward--mydesign::after { border-color: #eb8396; }

.stamp-reward__value {
  color: #f5f8e4;
  margin-right: 0.4rem;
  font-weight: 700;
}

@media (max-width: 768px) {
  .stamp-reward {
    padding: 0.6rem 2.5rem 0.4rem;
  }
  
  .stamp-reward::after {
    border-width: 0.5rem;
    height: 10rem;
  }

  .stamp-wrapper:nth-child(odd) .stamp-reward {
    top: auto;
    bottom: calc(100% + 7rem);
  }

  .stamp-wrapper:nth-child(even) .stamp-reward {
    bottom: auto;
    top: calc(100% + 7rem);
  }

  .stamp-wrapper:nth-child(even) .stamp-reward::after {
    top: auto;
    bottom: 100%;
    height: 10rem;
  }
}

@media (min-width: 768px) {
  .stamp-reward {
    font-size: 1.6rem;
  }
}

/* ------------------------------- */
/* Stamps on the main listing page */
/* ------------------------------- */

.achievement__spaces .stamp-wrapper {
  position: absolute;
  display: block;
}

.achievement__spaces--1 .stamp-wrapper { width: 11.6rem; top: 50%; left: 50%; transform: translate(-51%, -51%); }
.achievement--mydesign .achievement__spaces--1 .stamp-wrapper { width: 11.6rem; top: 50%; left: 50%; transform: translate(-48%, -54%) rotate(-13deg); }

.achievement__spaces--2 .stamp-wrapper { width: 11.1rem; }
.achievement__spaces--2 .stamp-wrapper--1 { top: 0; left: -0.1rem; }
.achievement__spaces--2 .stamp-wrapper--2 { top: 0; right: -0.1rem; }

.achievement__spaces--3 .stamp-wrapper { width: 10.3rem; }
.achievement__spaces--3 .stamp-wrapper--1 { top: -0.1rem; left: -0.3rem; transform: rotate(12deg); }
.achievement__spaces--3 .stamp-wrapper--2 { top: 0.1rem; left: 10.3rem; transform: rotate(-5deg); }
.achievement__spaces--3 .stamp-wrapper--3 { top: -0.2rem; left: 21.0rem; transform: rotate(2deg) }

.achievement__spaces--4 .stamp-wrapper { width: 7.2rem; }
.achievement__spaces--4 .stamp-wrapper--1 { top: 5.6rem; left: 0.2rem; transform: rotate(9deg); }
.achievement__spaces--4 .stamp-wrapper--2 { top: 0.7rem; left: 7rem; transform: rotate(-10deg); }
.achievement__spaces--4 .stamp-wrapper--3 { top: 5.4rem; left: 14.2rem; transform: rotate(3deg); }
.achievement__spaces--4 .stamp-wrapper--4 { top: 0.2rem; left: 20.4rem; transform: rotate(-3deg); }

.achievement__spaces--5 .stamp-wrapper { width: 8rem; }
.achievement__spaces--5 .stamp-wrapper--1 { top: 4.8rem; left: -0.1rem; transform: rotate(-9deg); }
.achievement__spaces--5 .stamp-wrapper--2 { top: 0.3rem; left: 5.9rem; transform: rotate(6deg); }
.achievement__spaces--5 .stamp-wrapper--3 { top: 4.8rem; left: 12.3rem; transform: rotate(-2deg); }
.achievement__spaces--5 .stamp-wrapper--4 { top: 0; left: 18.6rem; transform: rotate(9deg); }
.achievement__spaces--5 .stamp-wrapper--5 { top: 5.1rem; left: 24.2rem; transform: rotate(-8deg); }

.achievement__spaces--6 .stamp-wrapper { width: 7rem; }
.achievement__spaces--6 .stamp-wrapper--1 { top: 5.1rem; left: -0.2rem; transform: rotate(-9deg); }
.achievement__spaces--6 .stamp-wrapper--2 { top: 0; left: 5.1rem; transform: rotate(6deg); }
.achievement__spaces--6 .stamp-wrapper--3 { top: 5.1rem; left: 10.5rem; transform: rotate(-13deg); }
.achievement__spaces--6 .stamp-wrapper--4 { top: 0; left: 15.9rem; transform: rotate(10deg); }
.achievement__spaces--6 .stamp-wrapper--5 { top: 5.1rem; left: 21.1rem; transform: rotate(-8deg); }
.achievement__spaces--6 .stamp-wrapper--6 { top: 0; left: 26.5rem; transform: rotate(8deg); }

.achievement__spaces .stamp__image {
  width: 100%;
  display: block;
}

.achievement__spaces .stamp__date {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

/* ---------------------- */
/* Stamps in the carousel */
/* ---------------------- */

.card-badges .stamp-wrapper {
  height: 14.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.card-badges .stamp { height: 100%; }

.card-badges--1 .stamp-wrapper { height: 15.5rem; }
.card-badges--6 .stamp-wrapper { height: 13rem; }

.card-badges--1 .stamp-wrapper--1 { transform: translate(-50%, -50%); }

.card-badges--1 .stamp--1 { transform: translate(-1%, -1%) rotate(0deg); }

.card-badges--2 .stamp-wrapper--1 { transform: translate(-111%, -50%); }
.card-badges--2 .stamp-wrapper--2 { transform: translate(11%, -50%); }

.card-badges--2 .stamp--1 { transform: translate(1%, -2%) rotate(-3deg); }
.card-badges--2 .stamp--2 { transform: translate(0%, -1%) rotate(0deg); }

.card-badges--3 .stamp-wrapper--1 { transform: translate(-172%, -50%); }
.card-badges--3 .stamp-wrapper--2 { transform: translate(-46%, -50%); }
.card-badges--3 .stamp-wrapper--3 { transform: translate(68%, -50%); }

.card-badges--3 .stamp--1 { transform: translate(4%, 0%) rotate(13deg); }
.card-badges--3 .stamp--2 { transform: translate(-5%, 0%) rotate(-8deg); }
.card-badges--3 .stamp--3 { transform: translate(-2%, 0%) rotate(5deg); }

.card-badges--4 .stamp-wrapper--1 { transform: translate(-218%, -50%); }
.card-badges--4 .stamp-wrapper--2 { transform: translate(-106%, -50%); }
.card-badges--4 .stamp-wrapper--3 { transform: translate(6%, -50%); }
.card-badges--4 .stamp-wrapper--4 { transform: translate(118%, -50%); }

.card-badges--4 .stamp--1 { transform: translate(-2%, -2%) rotate(9deg); }
.card-badges--4 .stamp--2 { transform: translate(1%, 1%) rotate(-7deg); }
.card-badges--4 .stamp--3 { transform: translate(4%, -2%) rotate(4deg); }
.card-badges--4 .stamp--4 { transform: translate(-2%, 1%) rotate(-3deg); }

.card-badges--5 .stamp-wrapper--1 { transform: translate(-260%, -50%); }
.card-badges--5 .stamp-wrapper--2 { transform: translate(-155%, -50%); }
.card-badges--5 .stamp-wrapper--3 { transform: translate(-51%, -50%); }
.card-badges--5 .stamp-wrapper--4 { transform: translate(52%, -50%); }
.card-badges--5 .stamp-wrapper--5 { transform: translate(159%, -50%); }

.card-badges--5 .stamp--1 { transform: translate(2%, 0%) rotate(8deg); }
.card-badges--5 .stamp--2 { transform: translate(-2%, 0%) rotate(-5deg); }
.card-badges--5 .stamp--3 { transform: translate(-1%, 0%) rotate(3deg); }
.card-badges--5 .stamp--4 { transform: translate(2%, 0%) rotate(11deg); }
.card-badges--5 .stamp--5 { transform: translate(0%, 0%) rotate(-9deg); }

.card-badges--6 .stamp-wrapper--1 { transform: translate(-309%, -50%); }
.card-badges--6 .stamp-wrapper--2 { transform: translate(-206%, -52%); }
.card-badges--6 .stamp-wrapper--3 { transform: translate(-104%, -50%); }
.card-badges--6 .stamp-wrapper--4 { transform: translate(-1%, -52%); }
.card-badges--6 .stamp-wrapper--5 { transform: translate(101%, -50%); }
.card-badges--6 .stamp-wrapper--6 { transform: translate(205%, -52%); }

.card-badges--6 .stamp--1 { transform: translate(-3%, 0%) rotate(10deg); }
.card-badges--6 .stamp--2 { transform: translate(-2%, 0%) rotate(-6deg); }
.card-badges--6 .stamp--3 { transform: translate(1%, 0%) rotate(7deg); }
.card-badges--6 .stamp--4 { transform: translate(-1%, 0%) rotate(-1deg); }
.card-badges--6 .stamp--5 { transform: translate(3%, 0%) rotate(6deg); }
.card-badges--6 .stamp--6 { transform: translate(4%, 0%) rotate(-10deg); }

.card-badges .stamp__image {
  height: 100%;
  display: block;
}

.card-badges .stamp__date {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}