.achievement {
  width: 36.2rem;
  height: 21.6rem;
  border: 1rem solid #fefef2;
  background: #fde6d6;
  margin: 1.4rem;
  box-shadow: 0 0.2rem 0.5rem rgba(0,0,0, 0.1);
  background: #ced6ed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s;
  position: relative;
  cursor: pointer;
  outline: none;
}

@-moz-document url-prefix() { 
  .achievement { will-change: transform;}
}

.achievement:before {
  content: '';
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  width: 100%;
  height: 104%;
  background-size: cover;
  background-position: 0 -0.8rem;
}

@media(hover: hover) {
  .achievement:hover,
  .achievement:focus {
    transform: rotate(-2deg) scale(1.07);
  }

  .browser-edge .achievement:hover {
    transform: rotate(0deg) scale(1);
  }
}

.disable-hover .achievements { pointer-events: none; }

.achievement--event:before { background-image: url('../img/bg-event.png'); }
.achievement--fish:before { background-image: url('../img/bg-fish.png'); }
.achievement--communication:before { background-image: url('../img/bg-communication.png'); }
.achievement--insect:before { background-image: url('../img/bg-insect.png'); }
.achievement--diy:before { background-image: url('../img/bg-diy.png'); }
.achievement--hha:before { background-image: url('../img/bg-hha.png'); }
.achievement--plant:before { background-image: url('../img/bg-plant.png'); }
.achievement--smartphone:before { background-image: url('../img/bg-smartphone.png'); }
.achievement--money:before { background-image: url('../img/bg-money.png'); }
.achievement--negative:before { background-image: url('../img/bg-negative.png'); }
.achievement--landmaking:before { background-image: url('../img/bg-landmaking.png'); }
.achievement--mydesign:before { background-image: url('../img/bg-mydesign.png'); }

.achievement--event { background: #fbe4d2 url('../img/paper-bg.png') center repeat; }
.achievement--fish { background: #bceae0 url('../img/paper-bg.png') center repeat; }
.achievement--communication { background: #f9f5b8 url('../img/paper-bg.png') center repeat; }
.achievement--insect { background: #f8f9b5 url('../img/paper-bg.png') center repeat; }
.achievement--diy { background: #f9f2c6 url('../img/paper-bg.png') center repeat; }
.achievement--hha { background: #faf3bf url('../img/paper-bg.png') center repeat; }
.achievement--plant { background: #e5fac2 url('../img/paper-bg.png') center repeat; }
.achievement--smartphone { background: #bfdef0 url('../img/paper-bg.png') center repeat; }
.achievement--money { background: #faf9b8 url('../img/paper-bg.png') center repeat; }
.achievement--negative { background: #ded9f7 url('../img/paper-bg.png') center repeat; }
.achievement--landmaking { background: #d7efd0 url('../img/paper-bg.png') center repeat; }
.achievement--mydesign { background: #fde4e0 url('../img/paper-bg.png') center repeat; }

.achievement__new {
  width: 8rem;
  height: 3.4rem;
  background: url('../img/new-tab.png') center no-repeat;
  background-size: 100%;
  position: absolute;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffecee;
  font-weight: 700;
}

.achievement .achievement__new {
  bottom: 0.8rem;
  left: -2rem;
  font-size: 2rem;
}

.card .achievement__new {
  top: 1rem;
  left: 1rem;
  font-size: 2.4rem;
  padding-right: 0.4rem;
}

.achievement__title {
  font-size: 2.6rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  color: #72631e;
  text-align: center;
  padding-top: 1rem;
  margin-bottom: auto;
  z-index: 10;
}

.achievement__spaces {
  position: relative;
}

.achievement__spaces--1 { width: 11rem; margin-bottom: 0.8rem; }
.achievement__spaces--2 { width: 21.8rem; margin-bottom: 0.8rem; }
.achievement__spaces--3 { width: 31rem; margin-bottom: 1.4rem; }
.achievement__spaces--4 { width: 28rem; margin-bottom: 0.8rem; }
.achievement__spaces--5 { width: 32.2rem; margin-bottom: 0.8rem; }
.achievement__spaces--6 { width: 33.4rem; margin-bottom: 0.4rem; }

.achievement__spaces-svg { width: 100%; height: 100%; }

@media (max-width: 768px) {
  .card .achievement__new {
    transform: scale(1.5);
    transform-origin: top left;
  }
}

@media (min-width: 768px) {
  .card .achievement__new {
    top: -0.8rem;
    left: -3.2rem;
  }
}

/* ------------------ */
/* Stamp wrapper tier */
/* ------------------ */

.stamp-wrapper__tier {
  text-align: center;
  font-weight: 700;
  position: relative;
  z-index: 10;
}

.stamp-wrapper__tier--1 { margin-top: -0.4rem; }

.stamp-wrapper__tier--event { color: #f5a280; text-shadow: -0.4rem -0.4rem 0 #ffe0de, 0.4rem -0.4rem 0 #ffe0de, -0.4rem 0.4rem 0 #ffe0de, 0.4rem 0.4rem 0 #ffe0de; }
.stamp-wrapper__tier--fish { color: #2cc6c4; text-shadow: -0.4rem -0.4rem 0 #c0eae8, 0.4rem -0.4rem 0 #c0eae8, -0.4rem 0.4rem 0 #c0eae8, 0.4rem 0.4rem 0 #c0eae8; }
.stamp-wrapper__tier--communication { color: #eda80f; text-shadow: -0.4rem -0.4rem 0 #fbf2b0, 0.4rem -0.4rem 0 #fbf2b0, -0.4rem 0.4rem 0 #fbf2b0, 0.4rem 0.4rem 0 #fbf2b0; }
.stamp-wrapper__tier--insect { color: #a2c312; text-shadow: -0.4rem -0.4rem 0 #f4ffb1, 0.4rem -0.4rem 0 #f4ffb1, -0.4rem 0.4rem 0 #f4ffb1, 0.4rem 0.4rem 0 #f4ffb1; }
.stamp-wrapper__tier--diy { color: #ba9f3e; text-shadow: -0.4rem -0.4rem 0 #fbf2c7, 0.4rem -0.4rem 0 #fbf2c7, -0.4rem 0.4rem 0 #fbf2c7, 0.4rem 0.4rem 0 #fbf2c7; }
.stamp-wrapper__tier--hha { color: #d1bd4a; text-shadow: -0.4rem -0.4rem 0 #f4edb7, 0.4rem -0.4rem 0 #f4edb7, -0.4rem 0.4rem 0 #f4edb7, 0.4rem 0.4rem 0 #f4edb7; }
.stamp-wrapper__tier--plant { color: #70b86e; text-shadow: -0.4rem -0.4rem 0 #caeaaa, 0.4rem -0.4rem 0 #caeaaa, -0.4rem 0.4rem 0 #caeaaa, 0.4rem 0.4rem 0 #caeaaa; }
.stamp-wrapper__tier--smartphone { color: #8982c4; text-shadow: -0.4rem -0.4rem 0 #beddef, 0.4rem -0.4rem 0 #beddef, -0.4rem 0.4rem 0 #beddef, 0.4rem 0.4rem 0 #beddef; }
.stamp-wrapper__tier--money { color: #b7b30c; text-shadow: -0.4rem -0.4rem 0 #f8fa9d, 0.4rem -0.4rem 0 #f8fa9d, -0.4rem 0.4rem 0 #f8fa9d, 0.4rem 0.4rem 0 #f8fa9d; }
.stamp-wrapper__tier--negative { color: #7b5189; text-shadow: -0.4rem -0.4rem 0 #cacdf3, 0.4rem -0.4rem 0 #cacdf3, -0.4rem 0.4rem 0 #cacdf3, 0.4rem 0.4rem 0 #cacdf3; }
.stamp-wrapper__tier--landmaking { color: #43a18d; text-shadow: -0.4rem -0.4rem 0 #c3ecc2, 0.4rem -0.4rem 0 #c3ecc2, -0.4rem 0.4rem 0 #c3ecc2, 0.4rem 0.4rem 0 #c3ecc2; }
.stamp-wrapper__tier--mydesign { color: #ef5e78; text-shadow: -0.4rem -0.4rem 0 #ffe4e2, 0.4rem -0.4rem 0 #ffe4e2, -0.4rem 0.4rem 0 #ffe4e2, 0.4rem 0.4rem 0 #ffe4e2; }

.achievement__spaces .stamp-wrapper__tier { display: none; }

@media (max-width: 768px) {
  .stamp-wrapper__tier {
    font-size: 3.5rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .stamp-wrapper:nth-child(even) .stamp-wrapper__tier {
    bottom: -6rem;
  }

  .stamp-wrapper:nth-child(odd) .stamp-wrapper__tier {
    top: -6rem;
  }
}

@media (min-width: 768px) {
  .stamp-wrapper__tier {
    font-size: 2.2rem;
  }

  .stamp-wrapper__tier--event { color: #f5a280; text-shadow: -0.2rem -0.2rem 0 #ffe0de, 0.2rem -0.2rem 0 #ffe0de, -0.2rem 0.2rem 0 #ffe0de, 0.2rem 0.2rem 0 #ffe0de; }
  .stamp-wrapper__tier--fish { color: #2cc6c4; text-shadow: -0.2rem -0.2rem 0 #c0eae8, 0.2rem -0.2rem 0 #c0eae8, -0.2rem 0.2rem 0 #c0eae8, 0.2rem 0.2rem 0 #c0eae8; }
  .stamp-wrapper__tier--communication { color: #eda80f; text-shadow: -0.2rem -0.2rem 0 #fbf2b0, 0.2rem -0.2rem 0 #fbf2b0, -0.2rem 0.2rem 0 #fbf2b0, 0.2rem 0.2rem 0 #fbf2b0; }
  .stamp-wrapper__tier--insect { color: #a2c312; text-shadow: -0.2rem -0.2rem 0 #f4ffb1, 0.2rem -0.2rem 0 #f4ffb1, -0.2rem 0.2rem 0 #f4ffb1, 0.2rem 0.2rem 0 #f4ffb1; }
  .stamp-wrapper__tier--diy { color: #ba9f3e; text-shadow: -0.2rem -0.2rem 0 #fbf2c7, 0.2rem -0.2rem 0 #fbf2c7, -0.2rem 0.2rem 0 #fbf2c7, 0.2rem 0.2rem 0 #fbf2c7; }
  .stamp-wrapper__tier--hha { color: #d1bd4a; text-shadow: -0.2rem -0.2rem 0 #f4edb7, 0.2rem -0.2rem 0 #f4edb7, -0.2rem 0.2rem 0 #f4edb7, 0.2rem 0.2rem 0 #f4edb7; }
  .stamp-wrapper__tier--plant { color: #70b86e; text-shadow: -0.2rem -0.2rem 0 #caeaaa, 0.2rem -0.2rem 0 #caeaaa, -0.2rem 0.2rem 0 #caeaaa, 0.2rem 0.2rem 0 #caeaaa; }
  .stamp-wrapper__tier--smartphone { color: #8982c4; text-shadow: -0.2rem -0.2rem 0 #beddef, 0.2rem -0.2rem 0 #beddef, -0.2rem 0.2rem 0 #beddef, 0.2rem 0.2rem 0 #beddef; }
  .stamp-wrapper__tier--money { color: #b7b30c; text-shadow: -0.2rem -0.2rem 0 #f8fa9d, 0.2rem -0.2rem 0 #f8fa9d, -0.2rem 0.2rem 0 #f8fa9d, 0.2rem 0.2rem 0 #f8fa9d; }
  .stamp-wrapper__tier--negative { color: #7b5189; text-shadow: -0.2rem -0.2rem 0 #cacdf3, 0.2rem -0.2rem 0 #cacdf3, -0.2rem 0.2rem 0 #cacdf3, 0.2rem 0.2rem 0 #cacdf3; }
  .stamp-wrapper__tier--landmaking { color: #43a18d; text-shadow: -0.2rem -0.2rem 0 #c3ecc2, 0.2rem -0.2rem 0 #c3ecc2, -0.2rem 0.2rem 0 #c3ecc2, 0.2rem 0.2rem 0 #c3ecc2; }
  .stamp-wrapper__tier--mydesign { color: #ef5e78; text-shadow: -0.2rem -0.2rem 0 #ffe4e2, 0.2rem -0.2rem 0 #ffe4e2, -0.2rem 0.2rem 0 #ffe4e2, 0.2rem 0.2rem 0 #ffe4e2; }
}