.title-with-icon {
  display: flex;
  align-items: center;
}

.title-icon {
  width: 2.4rem;
  margin-left: 1rem;
  display: block;
}

.card .title-icon {
  width: 3rem;
}