.carousel {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  background: #c2cff2 url('../img/paper-bg.png') center repeat;
  transition: top 0s, left 0s, opacity 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}

.carousel--hidden {
  /* Time to go learn more React and fix this, don't judge me */
  position: absolute !important;
  top: -9999px;
  left: -9999px;
  transition: top 0s 0.3s, left 0s 0.3s, opacity 0.3s;
  opacity: 0;
}

/* -------------------- */
/* Prev and next arrows */
/* -------------------- */

.carousel__button {
  width: 14.2rem;
  height: 25.4rem;
  position: absolute;
  top: 50%;
  z-index: 200;
  cursor: pointer;
  padding: 4rem;
  display: none;
}

.browser-edge .carousel__button {
  display: none;
}

@media (min-width: 768px) {
  .carousel__button {
    display: block;
  }
}

.carousel__button svg {
  width: 100%;
  height: 100%;
  fill: #f5f9d4;
  transition: fill 0.2s;
}

@media(hover: hover) {
  .carousel__button:hover svg {
    fill: #435fa3;
  }
}

.carousel__button--prev { left: 0; animation: bobLeft 2.5s infinite ease-in-out; }
.carousel__button--next { right: 0; animation: bobRight 2.5s infinite ease-in-out; }
.carousel__button--next svg { transform: rotate(180deg); }

.carousel--first-slide .carousel__button--prev { display: none; }
.carousel--last-slide .carousel__button--next { display: none; }

@keyframes bobLeft {
  0% { transform: translateX(0) translateY(-50%); }
  50% { transform: translateX(12px) translateY(-50%); }
  100% { transform: translateX(0) translateY(-50%); }
}

@keyframes bobRight {
  0% { transform: translateX(0) translateY(-50%); }
  50% { transform: translateX(-12px) translateY(-50%); }
  100% { transform: translateX(0) translateY(-50%); }
}