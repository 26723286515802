html {
  box-sizing: border-box;
  font-size: 30%;
  -webkit-text-size-adjust: none;
}

/* apply a natural box layout model to all elements, but allowing components to change */
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  height: 100vh;
  overflow: hidden;
  font-family: 'Nunito', sans-serif;
}

.root {
  height: 100vh;
  background: #c2cff2 url('./img/paper-bg.png') center repeat;
}

@media (min-width: 768px) {
  html { font-size: 37%; }
}

@media (min-width: 1024px) {
  html { font-size: 48%; }
}

@media (min-width: 1320px) {
  html { font-size: 62.5%; }
}