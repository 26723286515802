.nook-miles {
  position: absolute;
  top: 2rem;
  right: 3.4rem;
  background: url('../img/nook-miles-bg.svg') center no-repeat;
  width: 19rem;
  height: 10.7rem;
  transform: rotate(3deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 200;
}

@media (min-width: 768px) {
  .nook-miles {
    z-index: 1000;
  }
}

.nook-miles__icon {
  width: 6.6rem;
  height: 3.2rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.2rem 0 0 0.3rem;
}

.nook-miles__icon img {
  width: 90%;
  transform: rotate(2deg);
}

.nook-miles__count {
  width: 17.7rem;
  height: 6.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bfdcfe;
  font-size: 3.4rem;
  padding-top: 0.5rem;
  font-weight: 700;
}