.achievements-wrapper {
  height: 100vh;
}

.achievements-wrapper .simplebar-track { display: none; }

.achievements {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 15rem 0 17rem;
  max-width: 137rem;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .achievements {
    padding: 13rem 10rem 11.3rem 5rem;
  }
}

/* --------------- */
/* Mimic scrollbar */
/* --------------- */

.mimic-scroll {
  position: absolute !important;
  top: 14.4rem;
  right: 5rem;
  width: 1.2rem;
  height: calc(100% - 32rem);
  z-index: 40;
  display: none;
}

.mimic-scroll::after {
  content: '';
  position: absolute;
  top: calc(100% + 1.2rem);
  left: 50%;
  transform: translateX(-50%);
  width: 4.2rem;
  height: 4.2rem;
  background: url('../img/scroll-icon.svg') center no-repeat;
  background-size: 100%;
}

.mimic-scroll__content {
  height: 400px;
  width: 100px;
}

@media (min-width: 768px) {
  .mimic-scroll {
    display: block;
  }
}