.buttons {
  position: absolute;
  bottom: 2.6rem;
  right: 2.6rem;
  z-index: 1000;
  display: flex;
}

@media (min-width: 768px) {
  .buttons {
    bottom: 4rem;
    right: 4rem;
  }
}

/* ------ */
/* Button */
/* ------ */

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem 1.2rem 0.6rem 0.8rem;
  cursor: pointer;
  margin-left: 0.6rem;
  position: relative;
  text-decoration: none;
  border: 0;
  background: transparent;
  outline: none;
  transform: translate(-2rem, -2rem);
}

.button__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.button__icon {
  font-size: 2.4rem;
  font-weight: 600;
  width: 3.6rem;
  height: 3.6rem;
  background: #877861;
  color: #f5f7e1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.6rem;
  transition: background 0.2s;
  position: relative;
}

.button__icon__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media(hover: hover) {
  .button:hover .button__icon,
  .button:focus .button__icon { background: #435fa3; }
}

.button__text {
  font-size: 4rem;
  color: #877861;
  padding-top: 0.2rem;
  display: block;
}

.button--g { display: none; }
.button--b .button__text { padding-top: 0; }

@media (min-width: 768px) {
  .button { transform: scale(1) translateX(0); }

  .button__text {
    font-size: 2.6rem;
  }

  .button--g { display: flex; }
}