.date-svg {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 700;
}

.date-svg--event { fill: #ffffee; font-size: 4.1rem; letter-spacing: -0.1rem; }
.date-svg--fish { fill: #3c9bbb; font-size: 4.3rem; font-weight: 600; }
.date-svg--insect { fill: #ffffee; font-size: 4.3rem; font-weight: 600; }
.date-svg--communication { fill: #ffffee; font-size: 4.2rem; letter-spacing: -0.1rem; }
.date-svg--diy { fill: #ffffee; font-size: 4.2rem; }
.date-svg--hha { fill: #ffffee; font-size: 4.3rem; }
.date-svg--plant { fill: #ffffee; font-size: 4.2rem; letter-spacing: -0.1rem; }
.date-svg--smartphone { fill: #ffffee; font-size: 4.3rem; }
.date-svg--money { fill: #ffffee; font-size: 4.1rem; letter-spacing: -0.1rem; }
.date-svg--negative { fill: #ffffee; font-size: 4.1rem; letter-spacing: -0.1rem; }
.date-svg--landmaking { fill: #ffffee; font-size: 4.3rem; }
.date-svg--mydesign { fill: #ffffee; font-size: 4.4rem; }

.date-svg rect,
.date-svg path { fill: none; }
.date-svg textPath { text-anchor: middle; }

.card-badges--6 .date-svg,
.achievement__spaces--6 .date-svg {
  font-size: 3.9rem;
  top: -0.1rem;
}

@media (min-width: 768px) {
  .date-svg--event { font-size: 2.1rem; }
  .date-svg--fish { font-size: 2.3rem; }
  .date-svg--insect { font-size: 2.3rem; }
  .date-svg--communication { font-size: 2.2rem; }
  .date-svg--diy { font-size: 2.2rem; }
  .date-svg--hha { font-size: 2.3rem; }
  .date-svg--plant { font-size: 2.2rem; }
  .date-svg--smartphone { font-size: 2.3rem; }
  .date-svg--money { font-size: 2.1rem; }
  .date-svg--negative { font-size: 2.1rem; }
  .date-svg--landmaking { font-size: 2.3rem; }
  .date-svg--mydesign { font-size: 2.4rem; }

  .card-badges--6 .date-svg,
  .achievement__spaces--6 .date-svg {
    font-size: 1.9rem;
  }
}